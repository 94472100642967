const IP = 'localhost'
// const PORT = process.env.API_PORT || '8080'
// const PARENT_PORT = process.env.API_PORT || '8081'
const PORT = '8080'
const PARENT_PORT = '8081'

export const API_URL = `http://${IP}:${PORT}/sts`
export const PARENT_API_URL = `http://${IP}:${PARENT_PORT}/vixalopex`
// export const API_URL = `https://0fag77d9uk.execute-api.us-east-1.amazonaws.com/dev/sts`
// export const PARENT_API_URL = `https://85rwl42dwc.execute-api.us-east-1.amazonaws.com/dev/vixalopex`
export const API_CONFIG_TIMEOUT = 15000