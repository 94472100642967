// stripe action constants
import {
    GET_STRIPE_SECRET_KEY_SUCCESS,
    GET_STRIPE_PROMISE_KEY_SUCCESS,
    GET_STRIPE_CUSTOMER_SECRET_KEY_SUCCESS,
    GET_STRIPE_CUSTOMER_PROMISE_KEY_SUCCESS,
    CREATE_STRIPE_CUSTOMER_SUCCESS,
    CREATE_STRIPE_SUBSCRIPTION_SUCCESS,
    UPDATE_STRIPE_SUBSCRIPTION_SUCCESS,
    CANCEL_STRIPE_SUBSCRIPTION_SUCCESS
  } from "../actions/types";
  
  // default stripe state
  const defaultState = {
    secretKey: "",
    promiseKey: "",
    customerSecretKey: "",
    customerPromiseKey: "",
    customer: "",
    subscription: ""
  };
  
  // email reducer
  export default function init(state = defaultState, action) {
    switch (action.type) {
  
      case GET_STRIPE_SECRET_KEY_SUCCESS:
        return {
          ...state,
          secretKey: action.payload,
        };

      case GET_STRIPE_PROMISE_KEY_SUCCESS:
        return {
          ...state,
          promiseKey: action.payload,
        };

      case GET_STRIPE_CUSTOMER_SECRET_KEY_SUCCESS:
        return {
          ...state,
          customerSecretKey: action.payload,
        };

      case GET_STRIPE_CUSTOMER_PROMISE_KEY_SUCCESS:
        return {
          ...state,
          customerPromiseKey: action.payload,
        };

      case CREATE_STRIPE_CUSTOMER_SUCCESS:
        return {
          ...state,
          customer: action.payload,
        };

      case CREATE_STRIPE_SUBSCRIPTION_SUCCESS:
        return {
          ...state,
          subscription: action.payload,
        };

      case UPDATE_STRIPE_SUBSCRIPTION_SUCCESS:
        return {
          ...state,
          subscription: action.payload,
        };

      case CANCEL_STRIPE_SUBSCRIPTION_SUCCESS:
        // const existingSubscription = state.subscription;
        // delete existingSubscription.subscription_active
        const existingSubscription = { ...state.subscription, subscription_active: false }
        return {
          ...state,
            subscription: existingSubscription
        };
  
      default:
        return state;
    }
  };
  