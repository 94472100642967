/* eslint-disable */
// user action constants
import {
  CREATE_USER_SUCCESS,
  USER_AUTH_SUCCESS,
  USER_TOKEN_EXPIRED,
  CURRENT_USER,
  DELETE_USER_SUCCESS,
  GET_ALL_USERS_SUCCESS,
  UPDATE_USER_SUCCESS,
  SUBSCRIBED_USER,
  SET_ADMIN
} from "../actions/types";
import { getLocalStorage } from '../../utilities/localStorage'
const userId = getLocalStorage("userId")
const locationId = getLocalStorage("locationId")
const subLocationId = getLocalStorage("subLocationId")
const userLevel = getLocalStorage("userLevel")
const username = getLocalStorage("username")
const email = getLocalStorage("email")
const phoneNumber = getLocalStorage("phoneNumber")
const name = getLocalStorage("name")
const stripeId = getLocalStorage("stripeId")
const subscriptionId = getLocalStorage("subscriptionId")
const applicationId = getLocalStorage("applicationId")
const lastLogin = getLocalStorage("lastLogin")
const locations = getLocalStorage("locations")

const isAdmin = getLocalStorage("isAdmin")

// default user state
const defaultState = {
  authenticated: false,
  token: "",
  currentUser: {
    id: userId || null,
    username: username || null,
    name: name || null,
    user_level: userLevel || null,
    location_id: locationId || null,
    sub_location_id: subLocationId || null,
    stripe_id: stripeId || null,
    subscription_id: subscriptionId || null,
    status: null,
    email: email || null,
    phoneNumber: phoneNumber || null,
    application_id: applicationId || null,
    last_login: lastLogin || null,
    locations: locations || []
  },
  subscribedUser: {},
  newUser: {},
  users: [],
  isAdmin: isAdmin || false
};

// user reducer
export default (state = defaultState, action) => {
  switch (action.type) {
    case USER_AUTH_SUCCESS:
      return {
        ...state,
        authenticated: true,
        token: action.payload,
      };

    case CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      }

    case SET_ADMIN:
      return {
        ...state,
        isAdmin: action.payload,
      }
  
    case USER_TOKEN_EXPIRED:
      return {
        ...state,
        authenticated: false,
        token: "",
        currentUser: {
          id: null,
          username: null,
          name: null,
          user_level: null,
          location_id: null,
          sub_location_id: null,
          stripe_id: null,
          subscription_id: null,
          status: null,
          email: null,
          phoneNumber: null,
          application_id: null,
          last_login: null,
          locations: []
        },
        isAdmin: false
      };

    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };

    case DELETE_USER_SUCCESS:
      const filteredData = state.users.filter(user => user.id !== action.payload)
      return {
        ...state,
        users: filteredData
      }

    case CREATE_USER_SUCCESS:
      const existingArray = state.users;
      existingArray.push(action.payload);
      return {
        ...state,
        users: existingArray,
        newUser: action.payload
      }

    case UPDATE_USER_SUCCESS:
      const filteredArray = state.users.filter(user => user.id !== parseInt(action.payload.id))
      filteredArray.push(action.payload);
      return {
        ...state,
        users: filteredArray,
      }

    case SUBSCRIBED_USER:
      return {
        ...state,
        subscribedUser: action.payload
      }

    default:
      return state;
  }
};
