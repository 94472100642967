import { createStore, combineReducers } from "redux";
import { devToolsEnhancer } from "redux-devtools-extension";
import user from "../reducers/user";
import ui from "../reducers/ui";
import availableSessions from "../reducers/availableSessions";
import scheduledSessions from "../reducers/scheduledSessions";
import subscriptions from "../reducers/subscriptions";
import subscriptionLevels from "../reducers/subscriptionLevels";
import date from "../reducers/date";
import stripe from "../reducers/stripe";
import media from "../reducers/media";
import disclaimer from "../reducers/disclaimer";
import location from "../reducers/location";
import sublocation from "../reducers/sublocation";
import email from "../reducers/email";

import { DESTROY_SESSION } from "../actions/types";
// Combine all reducers.
const appReducer = combineReducers({
  user,
  ui,
  availableSessions,
  scheduledSessions,
  subscriptions,
  subscriptionLevels,
  date,
  stripe,
  media,
  disclaimer,
  location,
  sublocation,
  email
});

const rootReducer = (state, action) => {   
  // Clear all data in redux store to initial.
  if(action.type === DESTROY_SESSION)
     state = undefined;
  return appReducer(state, action);
};

export default createStore(rootReducer, devToolsEnhancer());
