/* src/App.js */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import CustomDialog from "./components/customDialog/CustomDialog"
import Alert from "./components/alert/Alert"
import "./assets/scss/app.scss";
import Main from './components/Main';
import ActiveComponent from './components/activeComponent/ActiveComponent'
import { clearLocalStorage, getLocalStorage } from "./config/utilities/localStorage"

function App() {
  const dispatch = useDispatch();
  const activeComponent = useSelector((state) => state.ui.activeComponent.component);
  const applicationId = getLocalStorage("applicationId")

  useEffect(() => {
    if(applicationId && parseInt(applicationId) !== 3){
      dispatch({ type: "DESTROY_SESSION" });
      clearLocalStorage()
    }
  }, [applicationId, dispatch]);

  return (
    <div className="App">
      {activeComponent ? <ActiveComponent /> : <Main />}
      <CustomDialog />
      <Alert />
    </div>
  );
}

export default App;
