/* eslint-disable */
// user action constants
import {
    SUBLOCATION,
    CREATE_SUBLOCATION_SUCCESS,
    SUBLOCATIONS_SUCCESS,
    UPDATE_SUBLOCATION_SUCCESS,
    DELETE_SUBLOCATION_SUCCESS
  } from "../actions/types";
import { getLocalStorage } from '../../utilities/localStorage'
const locationId = getLocalStorage("locationId")
const subLocationId = getLocalStorage("subLocationId")
const subLocationNameLocal = getLocalStorage("subLocationName")
  
  // default user state
  const defaultState = {
    subLocationName: {
      id: null || subLocationId,
      name: null || subLocationNameLocal,
      location_id: null || locationId,
      city: null,
      state: null,
      lat: null,
      lng: null
    },
    newSubLocation: {},
    subLocations: []
  };
  
  // user reducer
  export default (state = defaultState, action) => {
    switch (action.type) {

      case SUBLOCATION:
        return {
          ...state,
          subLocationName: action.payload
        }

      case SUBLOCATIONS_SUCCESS:
        return {
          ...state,
          subLocations: action.payload
        }

      case CREATE_SUBLOCATION_SUCCESS:
        return {
          ...state,
          subLocations: [...state.subLocations, action.payload], // Add new sublocation immutably
          newSubLocation: action.payload, // Store the newly created sublocation
        };
      
      case UPDATE_SUBLOCATION_SUCCESS:
        return {
          ...state,
          subLocations: state.subLocations.map(subLocation =>
            subLocation.id === parseInt(action.payload.id) ? action.payload : subLocation // Replace updated sublocation
          ),
        };
      
      case DELETE_SUBLOCATION_SUCCESS:
        return {
          ...state,
          subLocations: state.subLocations.filter(subLocation => subLocation.id !== action.payload), // Filter out deleted sublocation
        };
    

      default:
        return state;
    }
  };
  