//user
export const USER_AUTH_SUCCESS = "USER_AUTH_SUCCESS";
export const USER_TOKEN_EXPIRED = "USER_TOKEN_EXPIRED";
export const CURRENT_USER = "CURRENT_USER";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const SUBSCRIBED_USER = "SUBSCRIBED_USER";
export const SET_ADMIN = 'SET_ADMIN'

//ui
export const DESTROY_SESSION = "DESTROY_SESSION";
export const UI_SET_ALERT = "UI_SET_ALERT";
export const UI_SET_CONTENT = "UI_SET_CONTENT";
export const UI_OPEN_CUSTOM_DIALOG = "UI_OPEN_CUSTOM_DIALOG";
export const UI_CLOSE_CUSTOM_DIALOG = "UI_OPEN_CUSTOM_DIALOG";
export const UI_SET_ACTIVE_COMPONENT = "UI_SET_ACTIVE_COMPONENT";
export const UI_SET_ACTIVE_ADMIN_COMPONENT = "UI_SET_ACTIVE_ADMIN_COMPONENT";

//date
export const UI_SET_DATE = "UI_SET_DATE";
export const UI_SET_SELECTED_DATE = "UI_SET_SELECTED_DATE";

//email
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';

//available sessions
export const AVAILABLE_SESSION = 'AVAILABLE_SESSION';
export const CREATE_AVAILABLE_SESSION_SUCCESS = 'CREATE_AVAILABLE_SESSION_SUCCESS';
export const AVAILABLE_SESSIONS_SUCCESS = 'AVAILABLE_SESSIONS_SUCCESS';
export const UPDATE_AVAILABLE_SESSION_SUCCESS = 'UPDATE_AVAILABLE_SESSION_SUCCESS';
export const DELETE_AVAILABLE_SESSION_SUCCESS = 'DELETE_AVAILABLE_SESSION_SUCCESS';
export const AVAILABLE_SESSIONS_REPORT = "AVAILABLE_SESSIONS_REPORT";

//scheduled sessions
export const SCHEDULED_SESSION = 'SCHEDULED_SESSION';
export const CREATE_SCHEDULED_SESSION_SUCCESS = 'CREATE_SCHEDULED_SESSION_SUCCESS';
export const SCHEDULED_SESSIONS_SUCCESS = 'SCHEDULED_SESSIONS_SUCCESS';
export const UPDATE_SCHEDULED_SESSION_SUCCESS = 'UPDATE_SCHEDULED_SESSION_SUCCESS';
export const DELETE_SCHEDULED_SESSION_SUCCESS = 'DELETE_SCHEDULED_SESSION_SUCCESS';
export const SCHEDULED_SESSIONS_REPORT = "SCHEDULED_SESSIONS_REPORT";
export const SCHEDULED_SESSIONS_BY_AVAILABLE_SESSION_ID = "SCHEDULED_SESSIONS_BY_AVAILABLE_SESSION_ID";
export const SCHEDULED_SESSIONS_LIMIT = "SCHEDULED_SESSIONS_LIMIT";
export const SCHEDULED_SESSIONS_BY_USER_COUNT = "SCHEDULED_SESSIONS_BY_USER_COUNT";

//subscriptions
export const SUBSCRIPTION = 'SUBSCRIPTION';
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTIONS_SUCCESS = 'SUBSCRIPTIONS_SUCCESS';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS';
export const SUBSCRIPTIONS_REPORT = "SUBSCRIPTIONS_REPORT"

//subscription levels
export const SUBSCRIPTION_LEVEL = 'SUBSCRIPTION_LEVEL';
export const CREATE_SUBSCRIPTION_LEVEL_SUCCESS = 'CREATE_SUBSCRIPTION_LEVEL_SUCCESS';
export const SUBSCRIPTION_LEVELS_SUCCESS = 'SUBSCRIPTION_LEVELS_SUCCESS';
export const UPDATE_SUBSCRIPTION_LEVEL_SUCCESS = 'UPDATE_SUBSCRIPTION_LEVEL_SUCCESS';
export const DELETE_SUBSCRIPTION_LEVEL_SUCCESS = 'DELETE_SUBSCRIPTION_LEVEL_SUCCESS';
export const SUBSCRIBER_SUBSCRIPTION = 'SUBSCRIBER_SUBSCRIPTION';
export const SUBSCRIBER_SUBSCRIPTION_LEVEL = 'SUBSCRIBER_SUBSCRIPTION_LEVEL';

//location
export const LOCATION = "LOCATION";
export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS";
export const LOCATIONS_SUCCESS = "LOCATION_SUCCESS";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS"

//sublocation
export const SUBLOCATION = "SUBLOCATION";
export const CREATE_SUBLOCATION_SUCCESS = "CREATE_SUBLOCATION_SUCCESS";
export const SUBLOCATIONS_SUCCESS = "SUBLOCATIONS_SUCCESS";
export const UPDATE_SUBLOCATION_SUCCESS = "UPDATE_SUBLOCATION_SUCCESS";
export const DELETE_SUBLOCATION_SUCCESS = "DELETE_SUBLOCATION_SUCCESS";

//media
export const MEDIA = "MEDIA";
export const CREATE_MEDIA_SUCCESS = "CREATE_MEDIA_SUCCESS";
export const ALL_MEDIA_SUCCESS = "ALL_MEDIA_SUCCESS";
export const UPDATE_MEDIA_SUCCESS = "UPDATE_MEDIA_SUCCESS";
export const DELETE_MEDIA_SUCCESS = "DELETE_MEDIA_SUCCESS";

//disclaimers
export const DISCLAIMER = "DISCLAIMER";
export const CREATE_DISCLAIMER_SUCCESS = "CREATE_DISCLAIMER_SUCCESS";
export const ALL_DISCLAIMERS_SUCCESS = "ALL_DISCLAIMERS_SUCCESS";
export const UPDATE_DISCLAIMER_SUCCESS = "UPDATE_DISCLAIMER_SUCCESS";
export const DELETE_DISCLAIMER_SUCCESS = "DELETE_DISCLAIMER_SUCCESS";

//stripe
export const GET_STRIPE_SECRET_KEY_SUCCESS = 'GET_STRIPE_SECRET_KEY_SUCCESS'
export const GET_STRIPE_PROMISE_KEY_SUCCESS = 'GET_STRIPE_PROMISE_KEY_SUCCESS'
export const GET_STRIPE_CUSTOMER_SECRET_KEY_SUCCESS = 'GET_STRIPE_CUSTOMER_SECRET_KEY_SUCCESS'
export const GET_STRIPE_CUSTOMER_PROMISE_KEY_SUCCESS = 'GET_STRIPE_CUSTOMER_PROMISE_KEY_SUCCESS'
export const CREATE_STRIPE_CUSTOMER_SUCCESS = "CREATE_STRIPE_CUSTOMER_SUCCESS";
export const CREATE_STRIPE_SUBSCRIPTION_SUCCESS = "CREATE_STRIPE_SUBSCRIPTION_SUCCESS";
export const UPDATE_STRIPE_SUBSCRIPTION_SUCCESS = "UPDATE_STRIPE_SUBSCRIPTION_SUCCESS";
export const CANCEL_STRIPE_SUBSCRIPTION_SUCCESS = "CANCEL_STRIPE_SUBSCRIPTION_SUCCESS";